import Vue from 'vue'
import VueRouter from 'vue-router'
import systemRoutes from './modules/system'
import store from '@/store/index'

//导入NProgress进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {clearToken, getToken} from "@/lib/user";
import {setNavigationStatus} from "@/lib/navigation";
import {Constants} from "@/lib/constants";
import {clearNavigationStatus} from "../lib/navigation";


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [...systemRoutes]
})

/**
 * 进入路由之前
 */
router.beforeEach((to, from, next) => {
    console.log("router index.js beforeEach：（" + from.path + "） （" + from.name + "） （" + to.path + "） （" + to.name + ")")
    if (to.name === ""){
        console.log("无具体页面")
        return
    }
    // 此逻辑 慎重修改， 恰好避免菜单动态加载死循环问题！！！
    NProgress.start();
    if (getToken() == null) {
        console.log("未登录")
        if (to.name === 'login') {//token不存在，并且是login页面
            console.log("跳转登陆页")
            next();
        } else {//token不存在，不是login页面
            console.log("重定向到登陆")
            // next('/login');
            next({name: "login"})
        }
    } else {
        console.log("已登录")
        if (to.name === 'login') {
            // 已登陆要去登陆页，是退出登陆的操作
            console.log("去登录")
            clearToken()
            next()
        } else {
            console.log("跳转")
            if (from.path === "/" && from.name == null && to.name == null) {
                // 刷新页面
                console.log("刷新页面，清除标志位，重新加载route")
                clearNavigationStatus()
            }
            next()
        }

    }
    NProgress.done();
})


/**
 * 进入路由之后
 */
router.afterEach((to, from) => {
    console.log("router index.js afterEach：（" + from.path + "） （" + from.name + "） （" + to.path + "） （" + to.name + ")")

    if (to.name !== 'login') {
        // 打开页面
        // sto1re.dispatch('addTab', to.path);
        store.dispatch('addTab', to);
        // NProgress.done();
    }
})

// /**
//  * 进入路由之前
//  */
// router.beforeEach((to, from, next) => {
//     console.log("router index.js beforeEach：（" + from.path + "） （" + from.name + "） （" + to.path + "） （" + to.name + ")")
//
//     // 此逻辑 慎重修改， 恰好避免菜单动态加载死循环问题！！！
//     NProgress.start();
//     if (getToken() != null) {
//         console.log("已登录")
//         // 已登录
//         if (to.name === 'login') {
//             // 已登录跳转登录页面
//             console.log("去登录")
//             clearToken()
//             next()
//             NProgress.done();
//         } else {
//             // console.log("普通跳转")
//             if (from.name == null) {
//                 next();
//             } else {
//                 if (from.name === 'login') {
//                     next()
//                 } else if (to.name != null && to.name !== from.name) {
//                     next();
//                 }
//                 NProgress.done();
//             }
//         }
//     } else {
//         console.log("未登录")
//         if (to.name === 'login') {//token不存在，并且是login页面
//             next();
//         } else {//token不存在，不是login页面
//             next('/login');
//         }
//         NProgress.done();
//     }
// })

// /**
//  * 进入路由之后
//  */
// router.afterEach((to, from) => {
//     console.log("router index.js afterEach：（" + from.path + "） （" + from.name + "） （" + to.path + "） （" + to.name + ")")
//     if (to.name !== 'login') {
//         store.dispatch('addTab', to.path);
//
//         NProgress.done();
//     }
// })


/**
 * 从后台动态加载前端路由的时候使用
 * @param params
 */
router.$addRoutes = function (params) {
    //router使用matcher来匹配前端路由，如果使用新建的matcher，就相当于清空了之前添加的路由
    router.matcher = new VueRouter().matcher;
    //重新添加路由
    for (let i = 0, length = params.length; i < length; i++) {
        router.addRoute(params[i]);
    }
    setNavigationStatus(Constants.navigationStatus)
    console.log("router index.js addRoutes ")
}


export default router
