import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.min.css'

Vue.use(ElementUI);

Vue.config.productionTip = false;


import * as echarts from "echarts/core";
import {GridComponent} from "echarts/components";
import {LineChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {UniversalTransition} from "echarts/features";
import {Constants} from "./lib/constants";
import iView from 'iview';
import 'iview/dist/styles/iview.css';    // 使用 CSS
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
Vue.prototype.$echarts = echarts
// 引入常量
Vue.prototype.global = Constants
Vue.use(iView);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
