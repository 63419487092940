import http from '@/utils/http';

// 登录
export const getSession = (data) => {
    return http({
        url: '/api/ca/v2/session/lp',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};
