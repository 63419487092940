import Login from '@/views/Login.vue'

//大部分的路由(实际上是菜单)，从后台数据库中动态获取
// 这里只定义一些固定菜单
export default [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        //name: 'home',
        component: () => import('@/views/Home.vue'),
        children: [
            // 默认打开首页
            {path: "", redirect: 'firstPage'},
        ]
    }
]
