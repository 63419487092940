// 全局常量
export const Constants = {
    "pageNum":  1, // 默认分页页码
    "pageSize":  8, // 默认分页数量
    "navigationStatus" : "FINISH", // 导航栏状态
}

/**
 * LocalStorage常量
 */
export const LocalStorageConstants = {
    "Token": "Token",
    "UserId": "UserId",
    "UserName": "UserName",
    "LoginName": "LoginName",
    "Navigation": "Navigation",
}
