/**
 * 合并字典
 * @param dict1
 * @param dict2
 * @returns {*}
 */
export const addDict = (dict1, dict2) => {
    const dict3 = {...dict1};
    for (var key in dict2) {
        dict3[key] = dict2[key];
    }

    return dict3
}

/**
 * 数组取差集
 * @param arr1
 * @param arr2
 * @returns {[]}
 */
export const arrDifferenceDet = (arr1, arr2) => {
    var set1 = new Set(arr1);
    var set2 = new Set(arr2);
    var subset = [];
    for (let item of set1) {
        if (!set2.has(item)) {
            subset.push(item);
        }
    }
    return subset;
}

/**
 * 判断是否是undefined
 * @param obj
 * @returns {boolean}
 */
export const isUndefined = (obj) =>{
    return typeof obj === 'undefined'
}
