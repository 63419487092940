/**
 * 设置token
 * @param response
 */
import {LocalStorageConstants} from "@/lib/constants";

export const setToken = (response) => {
    localStorage.setItem(LocalStorageConstants.Token, response.data.token);
    localStorage.setItem(LocalStorageConstants.UserId, response.data.userId);
    localStorage.setItem(LocalStorageConstants.UserName, response.data.userName);
    localStorage.setItem(LocalStorageConstants.LoginName, response.data.loginName);
}

/**
 * 获取当前登录用户名
 * @returns {string}
 */
export const getUserName = () => {
    return localStorage.getItem(LocalStorageConstants.UserName);
}

/**
 * 获取token
 * @returns {string}
 */
export const getToken = () => {
    return localStorage.getItem(LocalStorageConstants.Token);
}

/**
 * 清除token
 */
export const clearToken = () => {
    localStorage.clear();
}
