import axios from 'axios'
import {getToken} from "@/lib/user";
import {Message} from "element-ui";
import {clearToken} from "../lib/user";

const http = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    // headers: {
    //     'Content-Type': 'application/json; charset=utf-8'
    // }

})

/**
 * 请求拦截
 */
http.interceptors.request.use(
    config => {
        // 设置请求头
        if (getToken()) { // 判断token是否存在
            config.headers.Authorization = 'Bearer ' + getToken() // 存在就在每一个请求前添加token
        }
        return Promise.resolve(config)
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data['code'] === "111007" || response.data['code'] === "111009"){
                // 111007您的账号在其他地方已登录
                // 111009会话超时
                Message.error(response.data['message'])
                setTimeout(()=>{   //设置延迟执行
                    clearToken()
                    window.location.href = "/login"
                },2000)
                return response.data;
            }else{
                // 鉴权没问题
                return response.data;
            }


        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

// /**
//  * 请求地址处理
//  * @param {*} actionName action方法名称
//  */
// http.adornUrl = (actionName) => {
//     // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
//     return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
// }
//
// /**
//  * get请求参数处理
//  * @param {*} params 参数对象
//  * @param {*} openDefultParams 是否开启默认参数?
//  */
// http.adornParams = (params = {}, openDefultParams = true) => {
//     var defaults = {
//         't': new Date().getTime()
//     }
//     return openDefultParams ? merge(defaults, params) : params
// }
//
// /**
//  * post请求数据处理
//  * @param {*} data 数据对象
//  * @param {*} openDefultdata 是否开启默认数据?
//  * @param {*} contentType 数据格式
//  *  json: 'application/json; charset=utf-8'
//  *  form: 'application/x-www-form-urlencoded; charset=utf-8'
//  */
// http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
//     var defaults = {
//         't': new Date().getTime()
//     }
//     data = openDefultdata ? merge(defaults, data) : data
//     return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
// }

export default http
