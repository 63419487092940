import http from "@/utils/http";

//=====================新接口========================
/**
 * 获取导航菜单树
 * @param data
 * @returns {*}
 */
export const getMenuNavigationTree = (data) => {
    return http({
        url: '/api/ca/v2/navigation/tree',
        method: 'get',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取菜单树
 * @param data
 * @returns {*}
 */
export const getMenuTree = (data) => {
    return http({
        url: '/api/ca/v2/menu/tree',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 获取菜单列表
 * @param data
 * @returns {*}
 */
export const getMenuList = (data) => {
    return http({
        url: '/api/ca/v2/menu/list',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
};

/**
 * 更新菜单的一条数据记录
 * @param data
 * @returns {*}
 */
export const updateMenu = (data) => {
    return http({
        url: '/api/ca/v2/menu',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

/**
 * 新增一条菜单数据记录
 * @param data
 * @returns {*}
 */
export const addMenu = (data) => {
    return http({
        url: '/api/ca/v2/menu',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}


/**
 * 获取详情
 * @param data
 * @returns {*}
 */
 export const getMenu = (data) => {
    return http({
        url: '/api/ca/v2/menu',
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}


/**
 * 删除一条菜单数据记录
 * @param data
 * @returns {*}
 */
export const deleteMenu = (data) => {
    return http({
        url: '/api/ca/v2/menu',
        method: 'delete',
        params: data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}


// //=====================菜单管理接口开始========================
// //完整菜单树形列表接口
// export const getMenuTree = (menuQueryForm) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/tree',
//     method:'post',
//     data:qs.stringify({
//       menuNameLike:menuQueryForm.name,
//       menuStatus: menuQueryForm.status
//     })
//   })
// }

// //更新菜单的一条数据记录
// export const updateMenu = (menuForm) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/update',
//     method:'post',
//     data:menuForm
//   })
// }
//
// //新增一条菜单数据记录
// export const addMenu = (menuForm) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/add',
//     method:'post',
//     data:menuForm
//   })
// }
//
// //删除一条菜单数据记录
// export const deleteMenu = (menuForm) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/delete',
//     method:'post',
//     data:menuForm
//   })
// }

// //更新菜单的禁用状态
// export const changeMenuStatus = (menuId,status) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/status/change',
//     method:'post',
//     data:qs.stringify({
//       menuId,
//       status
//     })
//   })
// }
//
//
// //更新菜单的禁用状态
// export const changeMenuHidden = (menuId,hidden)  => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/hidden/change',
//     method:'post',
//     data:qs.stringify({
//       menuId,
//       hidden
//     })
//   })
// }
//
//
// //角色管理->分配菜单权限：获取菜单树形结构数据（带勾选节点和默认展开节点）
// export const getMenuCheckedTree = (roleId) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/checkedtree',
//     method:'post',
//     data:qs.stringify({
//       roleId:roleId
//     })
//   })
// }
//
// //角色管理->分配菜单权限：保存菜单树勾选节点(checkedIds是一个数字型数组)
// export const saveMenuCheckedKeys = (roleId,checkedIds) => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/savekeys',
//     method:'post',
//     data:{
//       roleId:roleId,
//       checkedIds:checkedIds
//     }
//   })
// }

// //根据登录用户名加载该用户可以访问的菜单
// export const getMenuTreeByUsername = () => {
//   return jwtServerInstance.request({
//     url:'/sysmenu/tree/user',
//     method:'post',
//     data:qs.stringify({
//       username:getTokenUser()
//     })
//   })
// }
