import {getMenuNavigationTree} from '../../api/ca/ca_menu'
import router from "@/router/index"
import systemRoutes from '@/router/modules/system'
import {Message} from "element-ui";
import {isFinishNavigation} from "@/lib/navigation";
import {isUndefined} from "../../utils/utils";
import fa from "element-ui/src/locale/lang/fa";

const HOME_PAGE = "/home"
const FIRST_PAGE = HOME_PAGE + "/firstpage"
const state = {
    //存放{ path: 路由路径对应path, name: tab名称, viewName: tab显示名称}对象数组
    maintabs: [{path: FIRST_PAGE, name: "FirstPage", closable: false, viewName: "首页", query:null}],
    // 当前被激活显示的那个Tab内容对应的route
    activePath: FIRST_PAGE,
    // 当前激活的Tab的功能名称
    activeTabName: "",
    // 有权限的菜单树(包括隐藏页面和功能点)
    menuList: [
        /*{  //这部分已经在后端“菜单管理”中进行动态加载，所以不需要静态代码配置
            id:0,
            name:"非菜单路由,但是需要显示Tab,请定义在这里",
            hidden:true,
            children:[
              {name:"首页",path:"/home/firstpage",hidden:true},
              {name:"个人中心",path:"/home/personal",hidden:true}
            ]
        },
        {   id:1,
            name:"系统管理",
            path:"/ca",
            icon:"el-icon-lock",
            children:[
                {id:3,name:"用户管理",path:"/home/sysuser"},
                {id:4,name:"角色管理",path:"/home/sysrole"},
                {id:6,name:"组织管理",path:"/home/sysorg"},
                {id:7,name:"菜单管理",path:"/home/sysmenu"},
                {id:8,name:"接口管理",path:"/home/sysapi"},
            ]
        },
        {   id:2,
            name:"订单管理",
            path:"/order",
            icon:"el-icon-eleme",
            children:[
                {id:5,name:"订单详情",path:"/home/order"},
            ]
        }*/
    ],

}
const actions = {
    /**
     * 打开标签
     * @param state
     * @param commit
     * @param path
     */
    // addTab({state, commit}, path) {s
    addTab({state, commit}, route) {
        const path = route.path
        if (!isFinishNavigation()) {
            // 未加载菜单，先请求数据
            getMenuNavigationTree().then((response) => {
                if (response.code === "0") {
                    // 成功
                    state.menuList = response.data;
                    commit("loadRoute", path); // 路由加载完成之后将Tab导航项添加
                } else {
                    Message.error(response.message);
                    console.error(response);
                }
            });
        }

        commit("addTabMutation",route); //菜单加载完成之后将Tab导航项添加
        // commit("addTabMutation", path); //菜单加载完成之后将Tab导航项添加
    }
}
const mutations = {

    /**
     * 加载后台菜单数据之后调用该方法
     * @param state
     * @param path
     */
    // addTabMutation(state, path) {
    addTabMutation(state, route) {
        const path = route.path
        const query = route.query
        //维护主界面Tab信息（从菜单数据中获取）
        let isAlreadyIn =
            state.maintabs.some(item => item.path === path)
        this.commit("findNameByPath", path);
        state.activePath = path;
        if (!isAlreadyIn && state.activeTabName !== "") {
            state.maintabs.push({
                path: path,
                name: state.activeTabName,
                query: query,
                viewName: findViewNameByName(state, state.activeTabName)
            });
        }
    },

    /**
     * 加载路由
     * @param state
     * @param path
     */
    loadRoute(state, path) {
        //重点从这里开始
        let loadedRoutes = []; //动态加载的前端路由初始化
        //将菜单转换为路由
        menuToRoutes(state.menuList, loadedRoutes)

        //固定路由与动态加载的前端路由进行组合
        for (let i in systemRoutes) {
            if (systemRoutes[i].path === HOME_PAGE) {
                systemRoutes[i].children = loadedRoutes
            }
        }

        //最后让路由生效
        router.$addRoutes(systemRoutes)
    },

    /**
     * 删除tab
     * @param state
     * @param path
     */
    removeTab(state, path) {
        if (path !== FIRST_PAGE) {
            state.maintabs = state.maintabs.filter(
                item => item.path !== path
            )
            state.activePath = state.maintabs[state.maintabs.length - 1].path
        }
    },

    /**
     * 寻找Name
     * @param state
     * @param path
     */
    findNameByPath(state, path) {
        let ret = _getChildfromState('path', path)
        if (ret != null){
            state.activeTabName = ret.name
        }
    },
}
const getters = {}

/**
 * 将菜单转换为前端路由
 * @param menuList
 * @param loadedRoutes
 */
function menuToRoutes(menuList, loadedRoutes) {
    menuList.forEach(item => {
        if (isUndefined(item.viewImport)) {
            loadedRoutes.push({
                path: item.path,
                name: item.name
            })
        } else {
            loadedRoutes.push({
                path: item.path,
                name: item.name,
                component: resolve => {
                    require(["@/views/" + item.viewImport], resolve)
                }
            })
        }

        //递归处理菜单树形结构
        menuToRoutes(item.children, loadedRoutes);
    });

}

/**
 * 寻找显示名称
 * @param state
 * @param name
 */
function findViewNameByName(state, name) {
    let ret = _getChildfromState('name', name)
    if (ret){
        return ret.viewName
    }
}

/**
 * 从MenuList中找到key为value的child
 * @param key
 * @param value
 * @returns {{children}|*|null}
 * @private
 */
function _getChildfromState(key, value){
    for (let i in state.menuList) {
        let ret = _getChildbyValue(state.menuList[i], key, value)
        if (ret != null) {
            return ret
        }
    }
    return null
}


/**
 * 根据key的value找到对应的item
 * @param child
 * @param key
 * @param value
 * @private
 */
function _getChildbyValue(child, key, value){
    if (child[key] === value){
        return child
    }

    if (child.children != null && child.children.length > 0) {
        for (let i in child.children) {
            let ret = _getChildbyValue(child.children[i], key, value)
            if (ret != null) {
                return ret
            }
        }
    }
    return null
}

/**
 *
 * @param flag
 * @returns {boolean}
 */
export const authFlag = (flag) =>{
    let ret = _getChildfromState('authFlag', flag)
    if (ret){
        return true
    }
    return false
}

export default {
    state, actions, mutations, getters
}

// //将菜单转换为前端路由
// function menuToRoutes(menuList, loadedRoutes) {
//     console.info(menuList.length);
//     for (let i in menuList) {
//         menuList[i].children.forEach(item => {
//             loadedRoutes.push({
//                 path: item.path,
//                 name: item.name,
//                 component: resolve => {
//                     require(["@/views/" + item.viewImport], resolve)
//                 }
//             })
//             //递归处理菜单树形结构
//             console.info(item.name)
//             menuToRoutes(item.children, loadedRoutes);
//         })
//     }
// }

// /**
//  * 寻找显示名称
//  * @param state
//  * @param name
//  */
// function findViewNameByName(state, name) {
//     let findOne;
//     for (let i in state.menuList) {
//         let tmpArr = state.menuList[i].children.filter(
//             item => item.name === name
//         )
//         if (tmpArr.length > 0) {
//             findOne = tmpArr[0]
//             break;
//         }
//     }
//     return findOne ? findOne.viewName : "";
// }
// /**
//  * 根据name 找viewName
//  * @param child
//  * @param path
//  * @returns {boolean}
//  * @private
//  */
// function _get_active_tab_by_name(child, name) {
//     if (child.children == null || child.children.length === 0) {
//         if (child.name === name) {
//             return child.viewName
//         }
//     } else {
//         for (let i in child.children) {
//             let ret = _get_active_tab_by_name(child.children[i], name)
//             if (ret != null) {
//                 return ret
//             }
//         }
//     }
//     return null
// }
//
// /**
//  * 根据path 设置active
//  * @param child
//  * @param path
//  * @returns {boolean}
//  * @private
//  */
// function _set_active_tab_by_path(child, path) {
//     if (child.children == null || child.children.length === 0) {
//         if (child.path === path) {
//             state.activeTabName = child.name
//             return true
//         }
//     } else {
//         child.children.forEach(item => {
//             if (_set_active_tab_by_path(item, path)) {
//                 return true
//             }
//         })
//     }
//     return false
// }
