import {Constants, LocalStorageConstants} from "@/lib/constants";
import {isUndefined} from "../utils/utils";

/**
 * 设置菜单栏加载状态
 * @param status
 */
export const setNavigationStatus = (status) => {
    localStorage.setItem(LocalStorageConstants.Navigation, status);
}

export const clearNavigationStatus = () => {
    localStorage.removeItem(LocalStorageConstants.Navigation)
}

/**
 * 是否完成本次导航
 * @param status
 */
export const isFinishNavigation = () => {
    let status = localStorage.getItem(LocalStorageConstants.Navigation)
    return !isUndefined(status) && status === Constants.navigationStatus
}

// /**
//  * 设置菜单栏加载状态
//  * @param status
//  */
// export const clearNavigationStatus = (status) => {
//     localStorage.setItem("navigation", status);
// }
